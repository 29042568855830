<template>
  <div fluid style="height: calc(100vh - 48px)">
    <CrmTopMenu />
<!--    <LeftSidebar />-->
    <router-view></router-view>
  </div>
</template>

<script>
import CrmTopMenu from "@/components/Employee/CrmTopMenu";
import LeftSidebar from "../../../components/Employee/LeftSidebar";

export default {
  components: {CrmTopMenu, LeftSidebar},
}
</script>

